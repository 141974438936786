"use client";

import { cn } from "@rallly/ui";
//import { Icon } from "@rallly/ui/icon";
import { ArrowUpRightIcon, ScrollTextIcon } from "lucide-react";
import Link from "next/link";

function TermsLink({
  href,
  children,
  showIcon = false,
}: {
  href: string;
  children: React.ReactNode;
  showIcon?: boolean;
}) {
  return (
    <Link
      href={href}
      target={"_blank"}
      className={cn(
        "text-muted-foreground border-transparent hover:bg-gray-200 focus:bg-gray-300 hover:text-primary",
        "group flex items-center gap-x-3 rounded-md px-3 py-2 text-sm font-semibold leading-6",
      )}
    >
      {showIcon && <ScrollTextIcon className={cn("size-5 shrink-0")} aria-hidden="true" />}
      {children}
      {/*<Icon>*/}
        <ArrowUpRightIcon />
      {/*</Icon>*/}
    </Link>
  );
}

export default function TermsOfUse({
  useIcons,
  wrap
}: {
  useIcons?: boolean;
  wrap?: boolean;
}) {
  return (
    <ul
      role="list"
      className={cn(
        wrap ? "group flex items-center justify-center" : "",
        "-mx-2 px-2",
      )}
    >
      {/*{ useIcons && <hr className="m-2" />}*/}
      <li>
        <TermsLink
          href="https://drive.google.com/file/d/1WTKHRU43fTrcbvQCM_CTehQNJEjko7XN/view?usp=sharing"
          showIcon={useIcons}
        >
          {/*<Trans i18nKey="termsOfUse" defaults="Terms of Use" />*/}
          {"Terms of Use"}
        </TermsLink>
      </li>
      <li>
        <TermsLink
          showIcon={useIcons}
          href="https://drive.google.com/file/d/1EpgidASAGQLZVDsZyI3BRSDINXY2EcwS/view?usp=sharing"
        >
          {"Kasutajatingimused"}
        </TermsLink>
      </li>
    </ul>
  );
}
